import { gql, useMutation } from 'urql';
import type { PlanDocument } from '@src/types';

export const useCreatePlanDocument = () => {
  const createPlanDocument = gql`
    mutation CreatePlanDocument($input: PlanDocumentInput!) {
      createPlanDocument(input: $input) {
        id
        environments
        properties {
          metadata {
            value {
              activationDate
              deactivationDate
              segments {
                fundingArrangements
                policyNumber
                pvrCodes
                segmentId
                situsStates
              }
            }
          }
          elements {
            title {
              value
            }
            href {
              value
            }
            mimeType {
              value
            }
          }
        }
      }
    }
  `;

  return useMutation<PlanDocument, { input: PlanDocument }>(createPlanDocument);
};
