import { UseQueryArgs, UseQueryResponse, gql, useQuery } from 'urql';
import type { PlanDocument } from '@src/types';

export const usePlanDocuments = (
  options?: Partial<UseQueryArgs>
): UseQueryResponse<{ planDocuments: PlanDocument[] }> => {
  const planDocuments = gql`
    query PlanDocuments {
      planDocuments {
        id
        environments
        properties {
          metadata {
            value {
              activationDate
              deactivationDate
              segments {
                fundingArrangements
                policyNumber
                pvrCodes
                segmentId
                situsStates
              }
            }
          }
          elements {
            title {
              value
            }
            href {
              value
            }
            mimeType {
              value
            }
          }
          title
        }
      }
    }
  `;

  return useQuery({
    query: planDocuments,
    ...options,
  });
};
