import { type CombinedError } from "urql";
import type { PlanDocument, PlanDocumentSegment } from "@src/types";
import React, { useState } from "react";
import { useDeletePlanDocument } from "@src/hooks";
import { PlanDocForm } from "@src/components";
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Divider } from '@abyss/web/ui/Divider';
import { Text } from '@abyss/web/ui/Text';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Alert } from '@abyss/web/ui/Alert';
import { Button } from '@abyss/web/ui/Button';
import { Link } from '@abyss/web/ui/Link';
import { Drawer } from '@abyss/web/ui/Drawer';
import { useOverlay } from '@abyss/web/hooks/useOverlay';
import { styled } from '@abyss/web/tools/styled';


type CustomMessagesDataTableProps = {
  data: PlanDocument[] | undefined;
  fetching: boolean;
  error: CombinedError | undefined;
  drawerData: { value: string[] } | undefined;
  dedupePolicyNumbers: (policyNumbers: string[] | undefined) => string[];
  generatePolicyNumberCell: (value: PlanDocumentSegment[]) => JSX.Element;
};

export const PlanDocumentsDataTable = ({ data, fetching, error, drawerData, dedupePolicyNumbers, generatePolicyNumberCell }: CustomMessagesDataTableProps) => {
  if (!data) return null;

  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState<boolean>(true);
  const [{ error: deletePlanDocError }, deletePlanDoc] = useDeletePlanDocument();

  const generateFundingArrangementCell = (
    value: PlanDocumentSegment[]
  ): string | JSX.Element[] => {
    const noSitusStatesOrFundingArrangements = value.every((segment) => {
      return (
        segment.situsStates.length === 0 &&
        segment.fundingArrangements.length === 0
      );
    });

    if (noSitusStatesOrFundingArrangements) return 'N/A';

    const segmentsWithFundingArrangements = value.filter(
      (segment) => segment.fundingArrangements.length > 0
    );

    return segmentsWithFundingArrangements.map(({ fundingArrangements, situsStates }, index) => {
      return (
        <React.Fragment>
          <div>
            <Text size="xs">
              <b>Funding Arrangements:</b> {fundingArrangements.join(', ')}
            </Text>
          </div>
          <div>
            <Text size="xs">
              <b> Situs States:</b> {situsStates.join(', ')}
            </Text>
          </div>

          {index !== segmentsWithFundingArrangements.length - 1 && <Divider />}
        </React.Fragment>
      );
    });
  };

  const EDIT_MODAL_ID = 'modal-form-plan-doc';
  const NEW_MODAL_ID = 'modal-form-plan-doc-new';

  const editModal = useOverlay(EDIT_MODAL_ID);
  const newModal = useOverlay(NEW_MODAL_ID);

  const individualActions = [
    {
      label: 'Edit',
      icon: <IconMaterial icon="edit" />,
      onClick: (row: PlanDocument) => {
        editModal.open({ value: row })
      },
      buttonVariant: true,
    },
    {
      label: 'Delete',
      icon: <IconMaterial icon="delete" />,
      onClick: ({ row }) => {
        if (!row?.values?.id) {
          console.error('No ID found for row', row);
          return;
        }
        deletePlanDoc({ input: { id: row.values.id } });

        if (deletePlanDocError) {
          console.error('Error deleting plan document', deletePlanDocError);
        }
      },
    },
  ];

  const dataTableProps = useDataTable({
    data,
    showGlobalFilter: true,
    showFilterDataset: true,
    minColumnWidth: 100,
    individualActions,
    initialColumns: [
      {
        Header: 'ID',
        accessor: 'id',
        width: 210,
      },
      {
        Header: 'Title',
        accessor: 'properties.elements.title.value',
        width: 200,
      },
      {
        Header: 'Document',
        accessor: 'properties.elements.href.value',
        width: 140,
        Cell: ({ value }: { value: string }) => {
          return (
            <Link href={value} target="_blank">
              View Document
            </Link>
          );

        },
      },
      {
        Header: 'Policy Numbers',
        accessor: 'properties.metadata.value.segments',
        id: 'policyNumbers',
        width: 100,
        Cell: ({ value }: { value: PlanDocumentSegment[] }) =>
          generatePolicyNumberCell(value),
      },
      {
        Header: 'Funding Arrangements',
        accessor: 'properties.metadata.value.segments',
        id: 'situsStates',
        Cell: ({ value }: { value: PlanDocumentSegment[] }) => (
          <div>{generateFundingArrangementCell(value)}</div>
        ),
      },
      {
        Header: 'Environments',
        accessor: 'environments',
        width: 110,
        Cell: ({ value }: { value: string[] }) => {
          if (!value) return 'N/A';
          // eslint-disable-next-line react/prop-types
          return value.join(', ');
        },
      },
      {
        Header: 'Activation Date',
        accessor: 'properties.metadata.value.activationDate',
        width: 100,
        Cell: ({ value }: { value: Date }) => {
          if (!value) return 'N/A';
          return new Date(value).toLocaleDateString();
        },
      },
      {
        Header: 'Deactivation Date',
        accessor: 'properties.metadata.value.deactivationDate',
        width: 100,
        Cell: ({ value }: { value: Date }) => {
          if (!value) return 'N/A';
          return new Date(value).toLocaleDateString();
        },
      },
    ],
  });



  const modalState = editModal.getState();

  const { id, environments, properties } = modalState?.data?.value?.row?.original || {};
  const { metadata, elements } = properties || {};
  const { href, title } = elements || {};
  const { value } = metadata || {};
  const { segments, activationDate, deactivationDate } = value || {};

  const fundingArrangementCodes = segments?.map((segment) => segment.fundingArrangements).flat();
  const situsStates = segments?.map((segment) => segment.situsStates).flat();

  const policyNumbers = dedupePolicyNumbers(segments
    ?.filter((segment) => segment.policyNumber)
    ?.map((segment) => segment.policyNumber));

  const initialValues =
  modalState?.data?.value?.row?.original ?
  {
    id,
    title: title?.value,
    href: href?.value,
    activationDate: activationDate ? new Date(activationDate).toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }) : '',
    deactivationDate: deactivationDate ? new Date(deactivationDate).toLocaleDateString(
      undefined,
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }
    ) : '',
    environments,
    policyNumbers,
    fundingArrangements: [...fundingArrangementCodes, ...situsStates],
  } : undefined;

  return (
    <Container>
      <LoadingSpinner
        ariaLoadingLabel="Downloading data"
        isLoading={fetching}
      />
      {error && (
        <Alert
          title="Error"
          variant="error"
          isVisible={isErrorAlertOpen}
          onClose={() => setIsErrorAlertOpen(false)}
        >
          {error.message}
        </Alert>
      )}
      <DataTable title="Plan Documents" tableState={dataTableProps} />

      <Button rounded size="$lg" onClick={() => newModal.open()}>
        <IconMaterial icon="add" color="$white" isScreenReadable />
      </Button>

      <PlanDocForm id={EDIT_MODAL_ID} initialValues={initialValues} modal={editModal} isEdit />
      <PlanDocForm id={NEW_MODAL_ID} modal={newModal} />

      <Drawer title="Cell Overflow Data" model="cellOverflow-drawer" size="$lg">
        <Drawer.Section>
          {drawerData &&
            drawerData.value.join(', ')}
        </Drawer.Section>
      </Drawer>
    </Container>
  );
}

const Container = styled('section', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '1rem',
});
